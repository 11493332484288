
import { Watch, Mixins, Component, Ref, InjectReactive, Emit } from "vue-property-decorator";

import TablePagination from "@/mixins/tablePagination";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";

import { ElForm } from "element-ui/types/form";

// 工具
import { debounce, cloneDeep } from "lodash";

import { dataStoreDetection } from "@/api/data";

import dayjs from "dayjs";

@Component({
  components: {
      ETable,
  }
})
export default class StoreDataDetection extends Mixins(Resize, TablePagination) {
[x: string]: any;
    @Ref("addFormRef") AddFormRef!: ElForm;
    @InjectReactive() activeName!: string;
    @InjectReactive() storeTypeList!: Array<any>;
    
    queryForm = {
        times: [],
        storeType: undefined,
    }

    loading = false;
    tableData = [];

    columns = [
        { label: "门店类型", prop: "storeType" },
        { label: "门店名称", prop: "storeName" },
        { label: "舌诊次数", prop: "num10001", sortable: true },
        { label: "脉诊次数", prop: "num10003", sortable: true },
        { label: "体质辨识次数", prop: "num10004", sortable: true },
        { label: "肺功能检测次数", prop: "num10005", sortable: true },
        { label: "总计", prop: "numAll", sortable: true },
    ]

    mounted() {
        this.windowResize(280);
    }

    //重置分页参数
    resetPages() {
        this.currentPage = 1;
    }

    // 搜索
    handleSearch() {
        this.resetPages();
        this.getData();
    }

    @Watch("activeName", { immediate: true })
    getData() {
        if (this.activeName !== "first") return;
        const { times } = this.queryForm;
        const params = {
            ...this.queryForm,
            startTime: (times || [])[0] && dayjs((times || [])[0]).format("YYYY-MM-DD HH:mm:ss"),
            endTime: (times || [])[1] && dayjs((times || [])[1]).format("YYYY-MM-DD HH:mm:ss"),
        }
        this.loading = true;
        dataStoreDetection(params).then((res) => {
            const list = res.data;
            this.tableData = list;
            this.tableData.forEach((el) => {
                el.storeTypeDesc = this.storeTypeList.find(el2 => el2.val == el.storeType)?.name;
            })
        }).finally(() => {
            this.loading = false;
        })
    }

    @Watch("storeTypeList", { deep: true })
    formatTableData() {
        if (!this.storeTypeList?.length) return;
        this.tableData.forEach((el) => {
            el.storeTypeDesc = this.storeTypeList.find(el2 => el2.val == el.storeType)?.name;
        })
    }
}
