import { service } from '@/ajax/request'

export const dataStoreDetection = (params: any) => (
    service({
        url: '/user/userMemberDetection/storeDetection',
        method: 'POST',
        params,
    })
)

export const dataStoreRecuperate = (params: any) => (
    service({
        url: '/pms/pmsProductExpend/storeData',
        method: 'POST',
        params,
    })
)
