import { service } from '@/ajax/request'

// 删除指定用户信息
export const getMIniPer = (params) => (
  service({
    url: `/app/appMiniCompose/list`,
    method: 'POST',
    params
  })
)

// 获取一级导航列表
export const getNavList = (parentKey) => (
    service({
      url: `/sys/sysDict/listByParentkey/${parentKey}`,
      method: 'POST',
    })
)

// 获取一级导航列表
export const miniComposeSaveOrUpdate = (params) => (
    service({
      url: `/app/appMiniCompose/saveOrUpdate`,
      method: 'POST',
      params
    })
)

// 获取一级导航列表
export const miniComposeRm = (id) => (
    service({
      url: `/app/appMiniCompose/delete/${id}`,
      method: 'POST',
    })
)

export const updateDict = (params) => (
    service({
      url: `/sys/sysDict/update/${params.id}`,
      method: 'POST',
      params
    })
)