
  import { Watch, Mixins, Component, Vue, Ref, Provide, ProvideReactive } from "vue-property-decorator";

  import TablePagination from "@/mixins/tablePagination";
  import Resize from "@/mixins/resize";

  import StoreDataDetection from "./components/detection.vue";
  import StoreDataRecuperate from "./components/recuperate.vue";

  import { getNavList as getNavListApi } from "@/api/mini";
  import watermark from "@/utils/watermark";

  
  @Component({
    inject: [],
    components: {
        StoreDataDetection, StoreDataRecuperate
    }
  })
  export default class UserDetectionRecord extends Mixins(Resize, TablePagination) {
    @ProvideReactive()
    activeName = "first";
    @ProvideReactive()
    storeTypeList = [];

    detectionTotal = [
        { quantity: 0, label1: "舌诊剩余可调用量", label2: "" },
        { quantity: 0, label1: "舌诊总分配次数", label2: "（含小程序/门店）" },
        { quantity: 0, label1: "舌诊总检测次数", label2: "（含小程序/门店）" },
        { quantity: 0, label1: "舌诊总购买次数", label2: "（小程序）" },
    ]

    mounted() {
        this.getData();
        this.getStoreTypeList();

        const username = localStorage.getItem("yysj_username");
        const markText = `${username} 禁止转发截图`;
        watermark.set(markText, document.querySelector(".content-box"));
    }

    destroyed() {
        watermark.hide();
    }

    // 查询门店类型字典
    getStoreTypeList() {
        getNavListApi('store_type').then((res) => {
            const dicts = res.data || [];
            const types = dicts.map(item => {
                return {name: item.dictValue, val: item.id};
            });
            this.storeTypeList = types;
        });
    }

    getData() {
        // detectionSummaryCount().then((res) => {
        //     const { allBuyNum, allUserBuy, allDetectionTimes, allLeaveNum } = res.data;
        //     this.detectionTotal[0].quantity = allLeaveNum;
        //     this.detectionTotal[1].quantity = allBuyNum;
        //     this.detectionTotal[2].quantity = allDetectionTimes;
        //     this.detectionTotal[3].quantity = allUserBuy;
        // })
    }
  }
